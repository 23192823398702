import React from "react";
import './App.css';
import Sidebar from "./Componentes/sidebar/Sidebar";
import Home from "./Componentes/home/Home";
import Sobremi from "./Componentes/sobremi/Sobremi";
import Servicios from "./Componentes/servicios/Servicios";
import Portfolio from "./Componentes/portfolio/Portfolio";

function App() {
  return (
        <>
            <Sidebar />
            <main className="main">
                <Home />
                <Sobremi />
                <Portfolio />
                <Servicios />
            </main>
        </>
  );

}

export default App;
