import React from "react";
import "./sidebar.css";
import logo from "../../assets/logo.svg";

const Sidebar = () => {
    return(
        <aside className='aside'>
            <a href='#home' className='nav_logo'>
                <img src={logo} alt='logo' className='nav_logo-img'/>
            </a>
            <nav className='nav'>
                <div className="nav_menu">
                    <ul className="nav_list">
                        <li className="nav_item">
                            <a href="#home" className="nav_link">
                                <i className="icon-home"></i>
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#sobremi" className="nav_link">
                                <i className="icon-user-following"></i>
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#servicios" className="nav_link">
                                <i className="icon-brifcase"></i>
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#curriculum" className="nav_link">
                                <i className="icon-graduation"></i>
                            </a>
                        </li>

                        <li className="nav_menu-item">
                            <a href="#servicios" className="nav_link">
                                <i className="icon-layers"></i>
                            </a>
                        </li>

                        <li className="nav_menu-item">
                            <a href="#portfolio" className="nav_link">
                                <i className="icon-note"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>

            <div className="nav_footer">
                <span className="copyright">&copy; 2024 </span>
            </div>
        </aside>
    )
}

export default Sidebar;