import React from "react";

const CabeceraContacto = () => {
    return(
        <div className="home_socials">
            <a href="https://www.linkedin.com" className="home_socials-link" rel="noreferrer" >
                <i className="fa-brands fa-linkedin"></i>
            </a>
            <span className="home_socials-separator"></span>
            <a href="https://github.com/" className="home_socials-link" rel="noreferrer" >
                <i className="fa-brands fa-github"></i>
            </a>
        </div>
    )
}

export default CabeceraContacto;