import React from "react";
import './home.css';
import Me from "../../assets/avatar-1.svg";
import CabeceraContacto from "./CabeceraContacto";
import ScrollDown from "./ScrollDown";
import Shapes from "./Shapes";

const Home = () => {
    return(
        <section className="home container" id="home">

            <div className="intro">
                <img src={Me} alt="" className="home_img" />
                <h1 className="home_name">Daniel Zafra</h1>
                <span className="home_education">Soy un desarrollador Backend con curiosidad por el fullstack</span>
                <CabeceraContacto />


                <a href="#contact" className="btn">Conóceme</a>

                <ScrollDown />
            </div>
            <Shapes />
        </section>
    )
}

export default Home;