import React from "react";

const ScrollDown = () => {
    return(
        <div className="scroll_down">
            <a href="#sobremi" className="mouse_wrapper">
                <span className="home_scroll-name"> Desliza hacia abajo!</span>
                <span className="mouse">
                    <span className="wheel"></span>
                </span>
            </a>
        </div>
    )
}
export default ScrollDown;